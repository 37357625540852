.bold {
  font-weight: 700;
}

.hideOnMobile {
  display: none;
}

.containerWrapper {
  padding: 0;
}

.topInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding-bottom: 10px;
}

.topInfo p,
.topInfo a {
  font-size: 12px;
  margin-bottom: 0;
}

.rightColumn {
  display: flex;
  column-gap: 8px;
}

.topInfoLink {
  display: flex;
  align-items: center;
  column-gap: 5px;
  color: var(--secondary-color);
}

@media (min-width: 768px) {
  .hideOnMobile {
    display: block;
  }

  .topInfo {
    border-bottom: 1px solid #dadada;
    flex-direction: row;
  }

  .leftColumn {
    display: flex;
    column-gap: 8px;
  }
}

@media (min-width: 992px) {
  .containerWrapper {
    padding: 0 15px;
  }

  .topInfo {
    padding-top: 10px;
  }
}

@media (min-width: 1200px) {
  .containerWrapper {
    min-width: var(--nav-width);
  }
}
