.primary {
  padding: 12px 25px;
  border-radius: 15px;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: var(--white);
  width: 100%;
}

.primary:hover {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
  outline: none;
}

.primary:disabled {
  opacity: 0.6;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: var(--white);
}

.secondary {
  composes: primary;
  color: var(--secondary-dark-blue-color);
  border-color: var(--secondary-background-color);
  background-color: var(--secondary-background-color);
}

.secondary:hover {
  color: white;
  border-color: var(--secondary-color);
  background-color: var(--secondary-color);
}

.outlined:hover {
  border-color: var(--secondary-color);
}
.wrapper {
  display: flex;
  justify-content: center;
  align-items: stretch;
  font-size: 15px;
}
.img {
  margin-right: 8px;
}
