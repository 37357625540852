.root {
  position: relative;
  background-color: var(--black);
  color: var(--white);
}

.root .rightElipse {
  position: absolute;
  right: 0;
  transform: translateY(-50%);
}

.root a {
  display: block;
  color: var(--white);
}

.container {
  padding: 60px 35px;
}

.column:not(:first-of-type) {
  padding-top: 30px;
}

.column .firstColumnContent {
  display: grid;
  grid-gap: 20px;
}

.logoComponent {
  margin: 0 auto 30px;
}

.column .placeInfo h6 {
  font-size: 14px;
  font-weight: 600;
  color: var(--white);
  line-height: 22px;
  margin: 0;
}

.column .contactInfo a {
  font-size: 14px;
  color: var(--white);
  line-height: 22px;
  margin-bottom: 5px;
}

.column .contactLinks a {
  font-size: 15px;
  font-weight: 500;
  color: #f0f3f5;
  line-height: 18px;
  margin-bottom: 10px;
}

.column .contactLinks a img {
  width: 12px;
  margin-left: 8px;
  filter: grayscale(1) invert(1);
}

.column .menuTitle {
  font-size: 22px;
  font-weight: 600;
  line-height: 32px;
  color: var(--white);
  margin-bottom: 30px;
}

.column .firstMenuItemContainer a,
.column .lastMenuItemContainer a {
  font-family: "Arial";
  font-size: 14px;
  line-height: 16px;
}

.column .firstMenuItemContainer a:not(:last-of-type),
.column .lastMenuItemContainer a {
  margin-bottom: 20px;
}

.socialBar {
  width: 100%;
  padding: 30px 0;
  background-color: #292a2c;
  color: var(--white);
}

.socialBarFooterContainer {
  text-align: center;
}

.socialBarFooterContainer p {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  color: var(--white);
  margin-bottom: 30px;
}

.socialBarFooterContainer a {
  display: block;
  font-size: 14px;
  line-height: 22px;
  color: #f0f3f5;
  margin-bottom: 0;
}

.socialBarFooterContainer a:not(:last-of-type) {
  margin-bottom: 5px;
}

.socialsWrapper {
  display: flex;
  align-items: center;
  column-gap: 15px;
  margin-bottom: 25px;
}

@media (min-width: 768px) {
  .root {
    text-align: left;
  }

  .column .lastMenuItemContainer {
    column-count: 2;
    column-gap: 20px;
  }

  .container {
    padding: 60px 0;
  }

  .column .menuTitle {
    font-size: 16px;
    line-height: 26px;
  }

  .socialBarFooterContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .socialBarFooterContainer p {
    margin-bottom: 0;
  }

  .socialBarFooterContainer a {
    display: inline-block;
  }

  .socialBarFooterContainer a:not(:last-of-type) {
    border-right: 1px solid var(--white);
    padding-right: 5px;
    margin-right: 5px;
  }
}

@media (max-width: 767px) {
  .root .rightElipse {
    display: none;
  }
}
