.dropdownLinkWrapper {
  display: flex;
  align-items: center;
  column-gap: 5px;
  width: fit-content;
  height: 20px;
  padding-right: 10px;
}

.flagIcon {
  margin-left: 5px;
  width: 20px;
  height: 20px;
  border-radius: 4px;
}

.mobileSubNavInfoWrapper,
.flagIconDropdownElem {
  display: none;
}

@media (max-width: 992px) {
  .languageNavbar {
    position: unset;
  }

  .mobileSubNavInfoWrapper {
    display: block;
    border-bottom: 1px solid #dfe6ef;
    padding-bottom: 15px;
    margin-bottom: 10px;
  }

  .backButton {
    display: flex;
    align-items: center;
    column-gap: 5px;
    color: #a5aab4;
    background-color: transparent;
    border: none;
    padding: 0;
    margin-bottom: 15px;
  }

  .backButton .backButtonArrow {
    width: 6px;
    height: 6px;
    border: solid #a5aab4;
    border-width: 0 1.5px 1.5px 0;
    display: inline-block;
    transform: rotate(135deg);
  }

  .parentName {
    color: #a5aab4;
    margin-bottom: 0;
  }

  .languageDropdownElem {
    display: flex;
  }

  .languageDropdownElem .flagIconDropdownElem {
    display: block;
    margin-right: 10px;
  }
}

@media (min-width: 786px) {
  .dropdownLinkWrapper {
    display: flex;
    column-gap: 10px;
    padding-right: 20px;
  }

  .languageNavbar > a::after {
    top: 40% !important;
  }
}
